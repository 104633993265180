import BigNumber from 'bignumber.js'
import nftStakingPoolABI from 'config/abi/nftStakingPool.json'
import cwigStaingPoolABI from 'config/abi/cwigStakingPool.json'
import fiwaStakingPoolABI from 'config/abi/fiwaStakingPool.json'
import {
  getNFTFactoryContract,
  getMasterchefContract,
  getCwigStakingContract,
  getFiwaStakingContract,
  getCwigStakingV2Contract,
  getFiwaStakingV2Contract,
} from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import {
  getNftStakingPoolAddress,
  getNFTFactoryAddress,
  getCwigStakingPoolAddress,
  getFiwaStakingPoolAddress,
  getCwigStakingPoolV2Address,
  getFiwaStakingPoolV2Address,
} from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'

export const fetchFarmApproval = async (account: string) => {
  const contract = getNFTFactoryContract(getNFTFactoryAddress())
  return contract.isApprovedForAll(account, getNftStakingPoolAddress())
}

// check approvalCWIG V1
export const fetchFarmCwigApproval = async (account: string) => {
  const contract = getNFTFactoryContract(getNFTFactoryAddress())
  return contract.isApprovedForAll(account, getCwigStakingPoolAddress())
}
// check approvalCWIG V2
export const fetchFarmCwigApprovalV2 = async (account: string) => {
  const contract = getNFTFactoryContract(getNFTFactoryAddress())
  return contract.isApprovedForAll(account, getCwigStakingPoolV2Address())
}

// check approvalFiwa
export const fetchFarmFiwaApproval = async (version: number, account: string) => {
  const contract = getNFTFactoryContract(getNFTFactoryAddress())
  return contract.isApprovedForAll(
    account,
    version === 1 ? getFiwaStakingPoolAddress() : version === 2 ? getFiwaStakingPoolV2Address() : '',
  )
}

export const fetchCwigFarmTotalDeposited = async (version: number, farmsToFetch: FarmConfig[]) => {
  const contractAddress = version === 1 ? getCwigStakingPoolAddress() : getCwigStakingPoolV2Address()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'poolInfo',
      params: [farm.pid],
    }
  })

  const rawStakedBalances = await multicall(cwigStaingPoolABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[4]._hex).toJSON()
  })
  return parsedStakedBalances
}

// staked balance v1 CWIG
export const fetchCwigFarmUserStakedBalances = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getCwigStakingPoolAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(cwigStaingPoolABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}
// staked balance v2 CWIG
export const fetchCwigFarmUserStakedBalancesV2 = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getCwigStakingPoolV2Address()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(cwigStaingPoolABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchCwigFarmUserLastTime = async (version: number, account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress =
    version === 1 ? getCwigStakingPoolAddress() : version === 2 ? getCwigStakingPoolV2Address() : ''

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(cwigStaingPoolABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[3]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFiwaFarmTotalDeposited = async (version: number, farmsToFetch: FarmConfig[]) => {
  const contractAddress = version === 1 ? getFiwaStakingPoolAddress() : getFiwaStakingPoolV2Address()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'poolInfo',
      params: [farm.pid],
    }
  })

  const rawStakedBalances = await multicall(fiwaStakingPoolABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[4]._hex).toJSON()
  })
  return parsedStakedBalances
}

// fetch staked balances in polls fiwa
export const fetchFiwaFarmUserStakedBalances = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getFiwaStakingPoolAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(fiwaStakingPoolABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFiwaFarmUserStakedBalancesV2 = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getFiwaStakingPoolV2Address()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(fiwaStakingPoolABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFiwaFarmUserLastTimeDeposit = async (
  version: number,
  account: string,
  farmsToFetch: FarmConfig[],
) => {
  const contractAddress =
    version === 1 ? getFiwaStakingPoolAddress() : version === 2 ? getFiwaStakingPoolV2Address() : ''

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(fiwaStakingPoolABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[3]._hex).toJSON()
  })
  return parsedStakedBalances
}

// fetch staked use earning CWIG V1
export const fetchCwigFarmUserEarnings = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getCwigStakingPoolAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'pendingGem',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(cwigStaingPoolABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

export const fetchCwigFarmUserEarningsV2 = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getCwigStakingPoolV2Address()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'pendingGem',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(cwigStaingPoolABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

// fetch staked user earning
export const fetchFiwaFarmUserEarnings = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getFiwaStakingPoolAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'pendingGem',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(fiwaStakingPoolABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

export const fetchFiwaFarmUserEarningsV2 = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getFiwaStakingPoolV2Address()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'pendingGem',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(fiwaStakingPoolABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

export const fetchFarmUserStakedBalances = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getNftStakingPoolAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(nftStakingPoolABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[4]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserDepositNFT = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getNftStakingPoolAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(nftStakingPoolABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[3]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: FarmConfig[]) => {
  const contractAddress = getNftStakingPoolAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'pendingGem',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(nftStakingPoolABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

export const fetchUserIncome = async (account: string, farm: FarmConfig) => {
  const masterChef = getMasterchefContract()

  const calls = [
    {
      address: masterChef.address,
      name: 'poolInfo',
      params: [farm],
    },
    {
      address: masterChef.address,
      name: 'userInfo',
      params: [farm, account],
    },
    {
      address: masterChef.address,
      name: 'totalAllocPoint',
      params: [],
    },
    {
      address: masterChef.address,
      name: 'gemPerBlock',
      params: [],
    },
  ]

  const [poolInfo, userInfo, totalAllocPoint, reward] = await multicall(nftStakingPoolABI, calls)
  const rewardPerBlock = reward / 10 ** 18

  const totalPowerSupply = poolInfo[4]
  const allocPoint = poolInfo[1]
  const deposited = userInfo[0]
  const bonus = userInfo[2] > 3000 ? 3000 : userInfo[2]

  let result = null
  if (totalPowerSupply && totalAllocPoint) {
    const income = (allocPoint / totalAllocPoint) * (deposited / totalPowerSupply) * rewardPerBlock * 28800
    result = (income * (10000 + bonus)) / 10000
  }
  return result
}

export const fetchAverageIncome = async (farm: FarmConfig) => {
  const masterChef = getMasterchefContract()

  const calls = [
    {
      address: masterChef.address,
      name: 'totalAllocPoint',
      params: [],
    },
    {
      address: masterChef.address,
      name: 'totalWarriorDeposited',
      params: [farm],
    },
    {
      address: masterChef.address,
      name: 'poolInfo',
      params: [farm],
    },
    {
      address: masterChef.address,
      name: 'gemPerBlock',
      params: [],
    },
  ]

  const [totalAllocPoint, totalWarriorDeposited, poolInfo, reward] = await multicall(nftStakingPoolABI, calls)
  const allocPoint = poolInfo[1]
  const rewardPerBlock = reward / 10 ** 18

  if (totalWarriorDeposited.toString() === '0') {
    return (allocPoint / totalAllocPoint) * rewardPerBlock * 28800 * 365
  }
  return (((allocPoint / totalAllocPoint) * rewardPerBlock * 28800) / totalWarriorDeposited) * 365
}

export const fetchTotalWarriorStaked = async (farm: FarmConfig) => {
  const masterChef = getMasterchefContract()
  return masterChef.totalWarriorDeposited(farm)
}

export const fetchCwigAPR = async (version: number, farm: FarmConfig) => {
  const cwigStakingContract = version === 1 ? getCwigStakingContract() : getCwigStakingV2Contract()

  // console.log("farm: ", farm);

  const calls = [
    {
      address: cwigStakingContract.address,
      name: 'totalAllocPoint',
      params: [],
    },
    {
      address: cwigStakingContract.address,
      name: 'poolInfo',
      params: [farm],
    },
    {
      address: cwigStakingContract.address,
      name: 'gemPerBlock',
      params: [],
    },
  ]

  const [totalAllocPoint, poolInfo, reward] = await multicall(cwigStaingPoolABI, calls)

  const poolBalance = poolInfo[4] / 10 ** 18
  const allocPoint = poolInfo[1]

  const rewardPerBlock = reward / 10 ** 18

  const yearlyReward = ((rewardPerBlock * allocPoint) / totalAllocPoint) * 28800 * 365
  if (poolBalance.toString() === '0') return 0
  return (yearlyReward / poolBalance) * 100
}

export const fetchFiwaAPR = async (version: number, farm: FarmConfig) => {
  const fiwaStakingPool = version === 1 ? getFiwaStakingContract() : getFiwaStakingV2Contract()

  const calls = [
    {
      address: fiwaStakingPool.address,
      name: 'totalAllocPoint',
      params: [],
    },
    {
      address: fiwaStakingPool.address,
      name: 'poolInfo',
      params: [farm.pid],
    },
    {
      address: fiwaStakingPool.address,
      name: 'fiwaPerBlock',
      params: [],
    },
  ]

  const [totalAllocPoint, poolInfo, reward] = await multicall(fiwaStakingPoolABI, calls)
  const poolBalance = poolInfo[4] / 10 ** 18
  const allocPoint = poolInfo[1]

  const rewardPerBlock = reward / 10 ** 18

  const yearlyReward = ((rewardPerBlock * allocPoint) / totalAllocPoint) * 365 * 28800

  if (poolBalance.toString() === '0') return 0

  return (yearlyReward / poolBalance) * 100
}

export const fetchFiwaFarmEndBlock = async (version: number, farmsToFetch: FarmConfig[]) => {
  const contractAddress = version === 1 ? getFiwaStakingPoolAddress() : getFiwaStakingPoolV2Address()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: contractAddress,
      name: 'poolInfo',
      params: [farm.pid],
    }
  })

  const poolInfo = await multicall(fiwaStakingPoolABI, calls)
  const endBlock = poolInfo.map((stakedBalance) => {
    return new BigNumber(stakedBalance[5]._hex).toJSON()
  })
  return endBlock
}

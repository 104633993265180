/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import farmsConfig from 'config/constants/farms'
import { FarmCategory } from 'config/constants/types'
import isArchivedPid from 'utils/farmHelpers'
// import priceHelperLpsConfig from 'config/constants/priceHelperLps'
import fetchFarms from './fetchFarms'
import {
  fetchCwigFarmTotalDeposited,
  fetchCwigFarmUserEarningsV2,
  fetchCwigFarmUserLastTime,
  fetchCwigFarmUserStakedBalancesV2,
  fetchFarmApproval,
  fetchFarmCwigApprovalV2,
  fetchFarmFiwaApproval,
  fetchFarmUserDepositNFT,
  fetchFarmUserEarnings,
  fetchFarmUserStakedBalances,
  fetchFiwaFarmEndBlock,
  fetchFiwaFarmTotalDeposited,
  fetchFiwaFarmUserEarningsV2,
  fetchFiwaFarmUserLastTimeDeposit,
  fetchFiwaFarmUserStakedBalancesV2,
} from './fetchFarmUser'
import { Farm, FarmsState } from '../types'

const noAccountFarmConfig = farmsConfig.map((farm) => ({
  ...farm,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0',
  },
}))

const initialState: FarmsState = { data: noAccountFarmConfig, loadArchivedFarmsData: false, userDataLoaded: false }

export const nonArchivedFarms = farmsConfig.filter(({ pid }) => !isArchivedPid(pid))

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<Farm[], { pids: number[]; type: number }>(
  'stakings/fetchFarmsPublicDataAsync',
  async ({ pids, type }) => {
    const farmsToFetch = farmsConfig.filter(
      (farmConfig) =>
        pids.includes(farmConfig.pid) &&
        farmConfig.category === (type === 1 ? FarmCategory.NFT : type === 2 ? FarmCategory.CWIG : FarmCategory.FIWA),
    )
    // console.log('PUBLIC', farmsToFetch)
    // Add price helper farms
    // const farmsWithPriceHelpers = farmsToFetch.concat(priceHelperLpsConfig)

    const farms = await fetchFarms(farmsToFetch)

    // Filter out price helper LP config farms
    const farmsWithoutHelperLps = farms.filter((farm: Farm) => {
      return farm.pid || farm.pid === 0
    })

    return farmsWithoutHelperLps
  },
)

interface FarmUserDataResponse {
  version: number
  category: FarmCategory
  pid: number
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
  lastTimeDeposit: number
  totalDeposit: number
}

export const fetchFarmUserDataAsync = createAsyncThunk<FarmUserDataResponse[], { account: string; pids: number[] }>(
  'stakings/fetchFarmUserDataAsync',
  async ({ account, pids }) => {
    const farmsToFetch = farmsConfig.filter(
      (farmConfig) => pids.includes(farmConfig.pid) && farmConfig.category === FarmCategory.NFT,
    )
    // console.log('NFT', farmsToFetch)
    // infor balance of NFT polls
    const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch)
    const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch)
    const approval = await fetchFarmApproval(account)
    const lastTimeDepositNFT = await fetchFarmUserDepositNFT(account, farmsToFetch)
    const dataNft = await userStakedBalances.map((farmAllowance, index) => {
      return {
        pid: farmsToFetch[index].pid,
        allowance: '1',
        tokenBalance: '1',
        stakedBalance: userStakedBalances[index],
        earnings: userFarmEarnings[index],
        approval,
        category: farmsToFetch[index].category,
        lastTimeDeposit: lastTimeDepositNFT[index],
      }
    })

    return dataNft
  },
)

export const fetchCwigFarmUserDataAsync = createAsyncThunk<FarmUserDataResponse[], { account: string; pids: number[] }>(
  'stakings/fetchCwigFarmUserDataAsync',
  async ({ account, pids }) => {
    const farmsToFetch = farmsConfig.filter(
      (farmConfig) => pids.includes(farmConfig.pid) && farmConfig.category === FarmCategory.CWIG,
    )
    // console.log('CWIG', farmsToFetch)
    // information of CWIG V2
    const userStakedBalancesCWIGV2 = await fetchCwigFarmUserStakedBalancesV2(account, farmsToFetch)
    const userFarmEarningsCWIGV2 = await fetchCwigFarmUserEarningsV2(account, farmsToFetch)
    const approvalCwigV2 = await fetchFarmCwigApprovalV2(account)
    const totalDepositedV2 = await fetchCwigFarmTotalDeposited(2, farmsToFetch)
    const lastTimeDepositCWIGAtV2 = await fetchCwigFarmUserLastTime(2, account, farmsToFetch)

    const dataNftCWIG = await userStakedBalancesCWIGV2.map((farmAllowance, index) => {
      return {
        pid: farmsToFetch[index].pid,
        allowance: '1',
        tokenBalance: totalDepositedV2[index],
        stakedBalance: userStakedBalancesCWIGV2[index],
        earnings: userFarmEarningsCWIGV2[index],
        approval: approvalCwigV2,
        category: farmsToFetch[index].category,
        version: farmsToFetch[index].version,
        lastTimeDeposit: lastTimeDepositCWIGAtV2[index],
        totalDeposit: totalDepositedV2[index],
      }
    })

    return dataNftCWIG
  },
)

export const fetchFiwaFarmUserDataAsync = createAsyncThunk<FarmUserDataResponse[], { account: string; pids: number[] }>(
  'stakings/fetchFiwaFarmUserDataAsync',
  async ({ account, pids }) => {
    const farmsToFetch = farmsConfig.filter(
      (farmConfig) => pids.includes(farmConfig.pid) && farmConfig.category === FarmCategory.FIWA,
    )
    // console.log('FIWA', farmsToFetch)
    const userStakedBalancesFIWAV2 = await fetchFiwaFarmUserStakedBalancesV2(account, farmsToFetch)
    const userFarmEarningsFIWAV2 = await fetchFiwaFarmUserEarningsV2(account, farmsToFetch)
    const approvalFIWAV2 = await fetchFarmFiwaApproval(2, account)
    const totalDepositedV2 = await fetchFiwaFarmTotalDeposited(2, farmsToFetch)
    const lastTimeDepositFIWAAtV2 = await fetchFiwaFarmUserLastTimeDeposit(2, account, farmsToFetch)
    const endTimeFIWAV2 = await fetchFiwaFarmEndBlock(2, farmsToFetch)

    const dataNftFiwa = await userStakedBalancesFIWAV2.map((farmAllowance, index) => {
      return {
        pid: farmsToFetch[index].pid,
        allowance: '1',
        tokenBalance: totalDepositedV2[index],
        stakedBalance: userStakedBalancesFIWAV2[index],
        earnings: userFarmEarningsFIWAV2[index],
        approval: approvalFIWAV2,
        category: farmsToFetch[index].category,
        version: farmsToFetch[index].version,
        lastTimeDeposit: lastTimeDepositFIWAAtV2[index],
        totalDeposit: totalDepositedV2[index],
        endTime: endTimeFIWAV2[index],
      }
    })
    return dataNftFiwa
  },
)

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setLoadArchivedFarmsData: (state, action) => {
      const loadArchivedFarmsData = action.payload
      state.loadArchivedFarmsData = loadArchivedFarmsData
    },
  },
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((farm) => {
        const liveFarmData = action.payload.find((farmData) => farmData.pid === farm.pid)
        return { ...farm, ...liveFarmData }
      })
    })

    // Update farms with user data
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.data.findIndex((farm) => farm.pid === pid && userDataEl.category === farm.category)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
    // Update farms with user data
    builder.addCase(fetchCwigFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.data.findIndex(
          (farm) => farm.pid === pid && userDataEl.category === farm.category && userDataEl.version === farm.version,
        )
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
    builder.addCase(fetchFiwaFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl
        const index = state.data.findIndex(
          (farm) => farm.pid === pid && userDataEl.category === farm.category && userDataEl.version === farm.version,
        )
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
  },
})

// Actions
export const { setLoadArchivedFarmsData } = farmsSlice.actions

export default farmsSlice.reducer

import React, { ForwardedRef, forwardRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'
import classnames from 'classnames'
import { Box } from '@pancakeswap/uikit'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import Misc from 'utils/misc'
import Media from 'utils/media'
// import { useQAList } from 'state/ui/hooks'

const StyledPage = styled.div`
  flex: 1;

  padding-bottom: 50px;
  padding-top: 32px;

  .q_a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: #ffce5f;
    box-shadow: inset 0px -4px 4px #ff9100;
    border-radius: 50%;
    position: fixed;
    bottom: 24px;
    right: 8vw;
    z-index: 99;

    ${Media.lessThan(Media.SIZE.XL)} {
      right: 24px;
    }
    ${Media.lessThan(Media.SIZE.LG)} {
      right: 16px;
      width: 45px;
      height: 45px;
    }
    ${Media.lessThan(Media.SIZE.MD)} {
      right: 12px;
      width: 40px;
      height: 40px;
    }
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    width: 100vw;
  }
`

const PageMeta = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const pageMeta = getCustomMeta(pathname, t) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  )
}

type PageProps = React.HTMLAttributes<HTMLDivElement> & {
  onScrollEnd?: () => void
  gitBook?: string
}

const Page = forwardRef(
  ({ children, onScrollEnd, className, ...props }: PageProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [ended, setEnded] = useState(false)
    // const [qaList] = useQAList()

    const handleScroll = useCallback(
      (e) => {
        const { scrollHeight, scrollTop } = e.target
        const offset = scrollHeight - (scrollTop + window.innerHeight + (Misc.isMobile ? 800 : 200))

        if (offset < 0 && !ended) {
          setEnded(true)
          onScrollEnd()
        } else if (offset >= 0) {
          setEnded(false)
        }
      },
      [ended, onScrollEnd],
    )

    // const gitBook = useMemo(
    //   () =>
    //     qaList.find((f) => {
    //       if (window.location.pathname === '/')
    //         return window.location.pathname === '/' && f.path === '/' && f.pageName.includes('GAME')

    //       return f.path !== '/' && window.location.pathname.includes(f.path)
    //     })?.gitBookLink,
    //   [qaList],
    // )

    return (
      <>
        <PageMeta />
        <StyledPage
          {...props}
          ref={ref}
          className={classnames('style-scrollbar', className)}
          onScroll={onScrollEnd && handleScroll}
        >
          <Box px={['16px', '20px']} mx="auto" maxWidth="1280px" className="container">
            {children}
          </Box>
          {/* {gitBook && (
            <Tooltip placement="left" trigger="hover" destroyTooltipOnHide overlay="We’re here to help!">
              <div className="q_a">
                <div>
                  <Clickable link as="a" href={gitBook} target="_blank" rel="noopener  noreferrer">
                    <img src="/images/icons/color-question-and-answer.svg" width="32px" alt="Q&A" />
                  </Clickable>
                </div>
              </div>
            </Tooltip>
          )} */}
        </StyledPage>
      </>
    )
  },
)

export default Page

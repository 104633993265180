import { useLogin } from 'hooks/useAuth'
import useToast from 'hooks/useToast'
import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from './Localization'

const FAST_INTERVAL = 10000
const SLOW_INTERVAL = 60000

const RefreshContext = React.createContext({ slow: 0, fast: 0 })

// Check if the tab is active in the user browser
const useIsBrowserTabActive = () => {
  const isBrowserTabActiveRef = useRef(true)

  useEffect(() => {
    const onVisibilityChange = () => {
      isBrowserTabActiveRef.current = !document.hidden
    }
    window.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])

  return isBrowserTabActiveRef
}

// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic refresh
const RefreshContextProvider = ({ children }) => {
  const [slow, setSlow] = useState(0)
  const [fast, setFast] = useState(0)
  const { toastWarning } = useToast()
  const { logined, logout: callLogout } = useLogin()
  const { t } = useTranslation()
  const isBrowserTabActiveRef = useIsBrowserTabActive()

  const handleChangeAccount = useCallback(() => {
    if (logined) {
      toastWarning(
        t('You have been logged out.'),
        t('Because you changed your metamask account. Log in again to proceed!'),
      )
    }
    callLogout()
  }, [callLogout, logined, t, toastWarning])

  const autoLogout = useCallback(async () => {
    if (logined) {
      await callLogout()
      window.location.reload()
    }
  }, [callLogout, logined])

  useEffect(() => {
    if (window?.ethereum) {
      window.ethereum.on('accountsChanged', handleChangeAccount)
    }
    return () => {
      window?.ethereum?.removeListener('accountsChanged', handleChangeAccount)
    }
  }, [handleChangeAccount])

  useEffect(() => {
    if (window?.ethereum) {
      window?.ethereum?.on('networkChanged', autoLogout)
    }
    return () => {
      window?.ethereum?.removeListener('networkChanged', autoLogout)
    }
  }, [autoLogout])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isBrowserTabActiveRef.current) {
        setFast((prev) => prev + 1)
      }
    }, FAST_INTERVAL)
    return () => clearInterval(interval)
  }, [isBrowserTabActiveRef])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isBrowserTabActiveRef.current) {
        setSlow((prev) => prev + 1)
      }
    }, SLOW_INTERVAL)
    return () => clearInterval(interval)
  }, [isBrowserTabActiveRef])

  return <RefreshContext.Provider value={{ slow, fast }}>{children}</RefreshContext.Provider>
}

export { RefreshContext, RefreshContextProvider }

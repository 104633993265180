import { ResetCSS } from '@pancakeswap/uikit'
import React, { lazy, useEffect, useState } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
// import styled from 'styled-components'
import ScrollToTop from 'components/ScrollToTop'
// import { ConfigAccountContext } from 'contexts/ConfigAccount/ConfigAccountContext'
// import AccountLayout from 'components/AccountLayout/AccountLayout'
// import { ModalPrortalContextProvider } from 'contexts/ModalPortal/ModalPortContext'
import GlobalStyle from './style/Global'
import PageLoader from './components/Loader/PageLoader'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
// import Typography from './components/Typography'
// import { ToastListener } from './contexts/ToastsContext'
import history from './routerHistory'
// import Maintain from './views/Maintain'

const MeraHome = lazy(() => import('./views/MeraHome'))
const Playing = lazy(() => import('./views/Playing'))
// const ConfigAccount = lazy(() => import('views/ConfigAccount/form/ConfigAccount'))
// const ClaimTokens = lazy(() => import('views/ClaimTokens/ClaimTokens'))
const NotFound = lazy(() => import('views/NotFound'))

const Guide = lazy(() => import('./views/Guide'))

// const MaintainModeBanner = styled.div`
//   position: relative;
//   height: 40px;
//   background-color: orange;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 2;

//   p {
//     color: black;
//     font-weight: bold;
//   }
// `

const App: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maintain, setMaintain] = useState(false)
  const [init, setInit] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [byPassMaintain, setByPassMaintain] = useState<boolean>(false)
  const [loadedTime, setLoadedTime] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (loadedTime < 1200) {
        setLoadedTime((lT) => lT + 500)
      }
    }, 500)
  }, [loadedTime])

  useEffect(() => {
    const hidePreloading = (check?: boolean) => {
      const preloading: any = document.getElementsByClassName('preloading')[0]

      if (preloading && check) {
        const fadeEffect = setInterval(() => {
          if (!preloading.style.opacity) {
            preloading.style.opacity = 1
          }
          if (preloading.style.opacity === '1') {
            preloading.style.opacity = 0
          } else {
            clearInterval(fadeEffect)
            preloading.style.display = 'none'
          }
        }, 200)
      }
    }

    setInit(false)

    const listenerLoad = () => {
      const image = document.querySelector('img')
      const isLoaded = image.complete && image.naturalHeight !== 0
      if (loadedTime > 1668) {
        hidePreloading(isLoaded)
      } else {
        setTimeout(() => {
          hidePreloading(isLoaded)
        }, 500) // 1888
      }
    }

    window.addEventListener('load', listenerLoad)

    return () => {
      window.removeEventListener('load', listenerLoad)
    }
  }, [loadedTime])

  // const shouldShowMaintain = maintain && !process.env.REACT_APP_BY_PASS_MAINTAIN

  // if (shouldShowMaintain && !byPassMaintain) {
  //   return (
  //     <>
  //       <ResetCSS />
  //       <GlobalStyle maintain />
  //       <Maintain />
  //     </>
  //   )
  // }

  if (init) return <></>

  return (
    <>
      {/* {shouldShowMaintain && (
        <MaintainModeBanner>
          <Typography size="large">In maintain mode</Typography>
        </MaintainModeBanner>
      )} */}
      <Router history={history}>
        <ResetCSS />
        <GlobalStyle />

        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Redirect exact from="/home" to="/" />
            <Redirect exact from="/mera" to="/" />
            <Redirect exact from="/playing" to="/game" />
            <Route exact strict path="/" component={MeraHome} />
            <Route exact strict path="/game" component={Playing} />
            <Route exact path="/guide" component={Guide} />
            {/* <ConfigAccountContext>
              <ModalPrortalContextProvider>
                <AccountLayout>
                  <Switch>
                    <Route exact path="/accounts" component={ConfigAccount} />
                    <Route exact path="/claim-tokens" component={ClaimTokens} />
                    <Route path="*" component={NotFound} />
                  </Switch>
                </AccountLayout>
              </ModalPrortalContextProvider>
            </ConfigAccountContext> */}
            <Route path="*" component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
        <ScrollToTop />
        {/* <ToastListener /> */}
      </Router>
    </>
  )
}

export default React.memo(App)

import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Prime Land x NFT',
  description: '',
  image: 'https://pancakeswap.finance/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `Prime Land`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | Prime Land x NFT`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | Prime Land x NFT`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | Prime Land x NFT`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | Prime Land x NFT`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | Prime Land x NFT`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | Prime Land x NFT`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | Prime Land x NFT`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | Prime Land x NFT`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | Prime Land x NFT`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | Prime Land x NFT`,
      }
    case '/warrior-batch':
      return {
        title: 'Buy Warrior | Prime Land x NFT',
      }
    default:
      return null
  }
}

import React, { createContext, ReactNode, useState } from 'react'
import { toast, ToastOptions } from 'react-toastify'
import styled from 'styled-components'
import Typography from 'components/Typography'
import { ToastContextApi } from './types'

const StyledToast = styled.div`
  min-height: 24px;
  display: flex;
  align-items: center;

  .description {
    line-height: 1.15rem;
  }
`
export const Toast: React.FC<{ title?: React.ReactNode | string; description: React.ReactNode | string }> = ({
  title,
  description,
}) => {
  return (
    <StyledToast>
      <Typography changaFont size="huge">
        {description ? '' : title}
      </Typography>
      <Typography className="description">{description}</Typography>
    </StyledToast>
  )
}

export const ToastsContext = createContext<ToastContextApi>(undefined)

export const ToastsProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastContextApi['toasts']>([])
  const toastOption: ToastOptions = { theme: 'dark' }

  const toastError = (title: string, description?: ReactNode) => {
    return toast.error(<Toast title={title} description={description} />, toastOption)
  }
  const toastInfo = (title: string, description?: ReactNode) => {
    return toast.info(<Toast title={title} description={description} />, toastOption)
  }
  const toastSuccess = (title: string, description?: ReactNode) => {
    return toast.success(<Toast title={title} description={description} />, toastOption)
  }
  const toastWarning = (title: string, description?: ReactNode) => {
    return toast.warning(<Toast title={title} description={description} />, toastOption)
  }
  const clear = () => setToasts([])
  const remove = (id: string) => {
    setToasts((prevToasts) => prevToasts.filter((prevToast) => prevToast.id !== id))
  }

  return (
    <ToastsContext.Provider value={{ toasts, clear, remove, toastError, toastInfo, toastSuccess, toastWarning }}>
      {children}
    </ToastsContext.Provider>
  )
}

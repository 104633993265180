import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'
import './animation.scss'
import './tooltip.css'
import Media from 'utils/media'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle<{ backgroundPosition?: string; maintain?: boolean }>`
  * {
    font-family: 'Montserrat';
    font-size: 14px;
  }
  html{
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #141414;
  }
  button{
    cursor: pointer;
    border:none;
    outline:none;
  }
  body {
    width: 100vw;
    height: 100vh;
    mix-blend-mode: revert;
    background-size: cover;
    scroll-behavior: smooth;
    overflow: hidden;
    padding: 0;
    margin: 0;
    transition: all 0.5s ease;

    // ${Media.greaterThan(Media.SIZE.XXL2)} {
    //   background-size: cover;
    // }
    // ${Media.lessThan(Media.SIZE.SM)} {
    //   background-size: cover;
    // }

    #root{
      height: 100%;
      display: flex;
      overflow: hidden;
    }

    img {
      height: auto;
      max-width: 100%;
      user-select: none;
    }

    div[role="presentation"] {
      background-color: rgba(0, 0, 0, 0.9);
    
    }
  }

  .style-scrollbar, * {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(150, 187, 250);
      border-radius: 10px;
      min-height: 40px;

      /* &:hover {
        background-color: #144693;
      } */
    }
  }

  // #particles-js {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  // }

  @font-face {
    font-family: 'ChakraPetchRegular';
    src: local('ChakraPetchRegular'), url('/fonts/ChakraPetchRegular.ttf');
  }

  @font-face {
    font-family: 'NotoSansJPRegular';
    src: local('NotoSansJPRegular'), url('/fonts/NotoSansJPRegular.ttf');
  }

  @font-face {
    font-family: 'RussoOne';
    src: local('RussoOne'), url('/fonts/RussoOne.ttf');
  }

  @font-face {
    font-family: 'GothicMedium';
    src: local('GothicMedium'), url('/fonts/GothicA1-Medium.ttf');
  }

  @font-face {
    font-family: 'MicrosoftYahei';
    src: local('MicrosoftYahei'), url('/fonts/Microsoft_yahei.ttf');
  }
`

export default GlobalStyle
